import React from 'react';
import PropTypes from 'prop-types';

const images = require('./bundleLoader');

const DisneyNumber = ({ alt, digit, visible }) => {
  const image = images(`./${digit}.png`);
  if (visible) {
    return <img src={image.default} alt={alt} style={{ maxWidth: '30%' }} />;
  }
  return null;
};

DisneyNumber.propTypes = {
  alt: PropTypes.string,
  digit: PropTypes.string.isRequired,
  visible: PropTypes.bool,
};

DisneyNumber.defaultProps = {
  alt: undefined,
  visible: true,
};

export default DisneyNumber;
