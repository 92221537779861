import queryString from 'query-string';
import moment from 'moment';

const nextTripDate = '11-02-2022';

const getNextTripDate = () => getQueryStringValues();

const getQueryStringValues = () => {
  const parsedQueryString = queryString.parse(window.location.search);
  let defaultEventDateString = localStorage.getItem('nextTripDate') || nextTripDate;
  if (moment(defaultEventDateString) < moment()) {
    defaultEventDateString = nextTripDate;
  }
  const queryValues = { debug: false, eventDateString: defaultEventDateString };
  if (parsedQueryString && parsedQueryString.debug) {
    queryValues.debug = true;
  }
  if (parsedQueryString && parsedQueryString.date) {
    queryValues.eventDateString = parsedQueryString.date;
  }
  if (parsedQueryString && parsedQueryString.startDate) {
    queryValues.eventDateString = parsedQueryString.startDate;
  }
  return queryValues;
};

export default getNextTripDate;
