import React from 'react';
import './App.css';
import { getDaysUntil } from './dateFunctions';
import DisneyCounter from './DisneyCounter';
import getNextTripDate from './lib/upcomingTrips';

const version = process.env.REACT_APP_VERSION;

const App = () => {
  const { debug, eventDateString } = getNextTripDate();
  const daysRemaining = getDaysUntil(eventDateString);
  window.document.title = `${daysRemaining.replace(/^0+/, '')} days until disney!`;
  localStorage.setItem('nextTripDate', eventDateString);
  return (
    <div className="App" style={{ height: '100%', width: '100%', objectFit: 'contain' }}>
      <DisneyCounter daysRemaining={daysRemaining} />
      {debug ? <div style={{ color: 'white' }}>{version}</div> : null }
    </div>
  );
};

export default App;
