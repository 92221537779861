const moment = require('moment');

const getDateString = (eventDateString) => {
  let today = moment().format('MM-DD-YYYY');
  today = moment(today, 'MM-DD-YYYY');
  const eventDate = getDate(eventDateString);
  const diff = eventDate.diff(today, 'days');
  return getDayString(diff);
};

const getDate = (dateString) => {
  const eventDate = moment(dateString, 'MM-DD-YYYY');
  if (!eventDate.isValid()) {
    throw new Error(`${dateString} is not a valid date`);
  }
  return eventDate;
};

const getDayString = (days) => {
  if (days < 10) {
    return `00${days}`;
  }
  if (days < 100) {
    return `0${days}`;
  }
  return days.toString();
};

module.exports.getDaysUntil = getDateString;
