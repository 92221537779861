import React from 'react';
import PropTypes from 'prop-types';

import DisneyNumber from './DisneyNumber';
import placard from './images/placard.png';

const DisneyCounter = (props) => {
  const { daysRemaining } = props;
  const firstDigit = daysRemaining[0];
  const secondDigit = daysRemaining[1];
  const thirdDigit = daysRemaining[2];
  return (
    <div className="disney-number">
      <div>
        <DisneyNumber digit={firstDigit} visible={firstDigit !== '0'} alt="firstDigit" />
        <DisneyNumber digit={secondDigit} visible={!(firstDigit === '0' && secondDigit === '0')} alt="secondDigit" />
        <DisneyNumber digit={thirdDigit} alt="thirdDigit" />
      </div>
      <div><img src={placard} alt="days until our Disney vacation" style={{ maxWidth: '40%' }} /></div>
    </div>
  );
};

DisneyCounter.propTypes = {
  daysRemaining: PropTypes.string.isRequired,
};

export default DisneyCounter;
